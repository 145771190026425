export const technologies = [
  {
    title: "Core",
    techs: [
      "Full-Stack Web Developer", "Software Engineering", "Data Structure & Algorithms", "Artificial Intelligence", "Machine Learning", "Competitive Programming", "Database",
    ],
  },
  {
    title: "Languages",
    techs: [
      "Python", "Java", "C", "C++", 
      "C#", "R", "HTML", "CSS", "JavaScript",
      "SQL", "PL/SQL", "Verilog", "Prolog", "Assembly", "LaTeX"
    ],
  }, 
  {
    title: "Frameworks & Libraries",
    techs: [
      //Nodejs Reactjs Bootstrap Expressjs Django Spring Hibernate .NET JUnit NumPy Pandas SciPy TensorFlow Graphql Jquery Npm TensorFlow
      "React JS", "Node JS", "Angular", "Vue", "Django", "Spring Boot", "Spring MVC", ".Net Core", "ASP.NET", "Hibernate", "JSP", "Servlets", "JUnit",
      "GraphQL", "Bootstrap", "jQuery", "MUI", "Express JS", "AJAX", "NumPy", "Pandas", "SciPy", "TensorFlow"
    ],
  },
  {
    title: "Software & Tools",
    techs: [
      "Docker", "Jira", "Kubernetes", "Jenkins", "Git", "GitHub Actions", "Postman", "Colab", "IntelliJ IDEA", "Atom", "Wireshark", "Anaconda", "Visual Studio Code", "MySQL", "MongoDB", "Netbeans",
      "Eclipse", "Linux", "PyCharm", "PhpMyAdmin", "DosBox", "Packet Tracer", "Spyder", "Xampp"
    ],
  },
  {
    title: "Databases & Cloud hosting",
    techs: [
      //MySQL MongoDB Postgres Neo4J MicrosoftSQLServer Oracle AWS Azure Firebase GitHub Pages Heroku Netlify
      "MySQL", "MongoDB", "Postgres", "Neo4J", "Microsoft SQL Server", "Oracle", "AWS", "Azure", "Firebase", "GitHub Pages", "Heroku", "Netlify", "Render"
    ],
  },
];
