export const eduDetails = [
  {
    title: "Indiana University, Bloomington, Indiana",
    degree: "Master of Science in Computer Science (GPA: 4/4)",
    year: "August 2022 - May 2024",
    url: "https://www.indiana.edu/",
    iub_transcript: true,
  },
  {
    title: "Dharmsinh Desai University, Nadiad, Gujarat",
    degree: "Bachelor of Technology in Information Technology (Gold Medalist) (GPA: 9.32/10)",
    year: "August 2018 - May 2022",
    url: "https://ddu.ac.in/",
    ddu_degree: true,
    ddu_transcript: true,
    ddu_letter: true,
  },
  {
    title: "Divine Buds English School, Ahmedabad, Gujarat",
    degree: "Primary, Secondary, Middle and Higher Secondary School, Gujarat Board (GSEB)",
    year: "July 2006 - March 2018",
  },
];
