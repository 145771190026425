export const work = [
  {
    title: "Bolster AI",
    role: "Software Engineer",
    duration: "June 2024 - Present",
    description: "",
    url: "https://bolster.ai"
  },
  {
    title: "Synopsys Inc.",
    role: "Software Engineer Intern",
    duration: "May 2023 - August 2023",
    description: "",
    url: "https://www.synopsys.com/"
  },
  {
    title: "Indiana University, Bloomington",
    role: "Graduate Associate Instructor",
    duration: "August 2022 - May 2024",
    description: "",
    techs: [],
    url: "https://luddy.indiana.edu/"
  },
  {
    title: "Indian Space Research Organisation (ISRO)",
    role: "Software Engineer Intern",
    duration: "December 2021 - March 2022",
    description: "",
    techs: [],
    url: "https://www.isro.gov.in/",
    isro_certificate: true,
  },
  {
    title: "Four Clowns",
    role: "Software Engineer Intern",
    duration: "May 2021 - July 2022",
    description: "",
    techs: [],
    url: "",
    fourclowns_certificate: true,
  },
  {
    title: "Institution of Electronics and Telecommunication Engineers (IETE)",
    role: "Member of Students' Forum",
    duration: "December 2019 - November 2021",
    description: "",
    url: "https://www.iete.org/",
  },
  {
    title: "Codechef DDU Chapter",
    role: "Executive Team Member",
    duration: "February 2019 - June 2021",
    description: "",
    url: "https://www.linkedin.com/company/codechef-ddu-chapter/",
    codechef_certificate: true,
  },
];
