const BottomLine = () => {
  return (
    <div
      className="h-28 bg-gray-500"
      style={{
        width: "1px",
        backgroundColor: "#64ffda",
      }}
    />
  );
};

export default BottomLine;
