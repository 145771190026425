import React from 'react';

const IconCertificate = ({ url, pdt, pdr }) => (
    <a href={url} target="_blank" rel="noreferrer" style={{ paddingTop: pdt, paddingRight: pdr }}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 512 512" style={{ fill: "#000000" }}>
            <g fill="#64ffda" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}>
                <g>
                    <g>
                        <path d="M426.247,0H85.753c-5.632,0-10.199,4.566-10.199,10.199v491.602c0,5.633,4.567,10.199,10.199,10.199h340.494 c5.632,0,10.199-4.566,10.199-10.199V10.199C436.446,4.566,431.879,0,426.247,0z M416.048,491.602H95.952V20.398h320.096V491.602z "></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M392.099,205.892l-22.499-64.55c3.267-6.372,5.123-13.582,5.123-21.221c0.001-25.743-20.943-46.687-46.687-46.687 c-25.744,0-46.688,20.944-46.688,46.688c0,7.467,1.772,14.524,4.901,20.789l-28.827,63.934c-1.154,2.557-1.202,5.477-0.136,8.071 c1.067,2.595,3.155,4.635,5.774,5.641l42.569,16.363c1.201,0.462,2.44,0.681,3.656,0.681c3.931,0,7.66-2.286,9.335-6.087 l3.809-8.641l1.127,4.57c0.662,2.679,2.383,4.977,4.767,6.367c2.387,1.39,5.234,1.753,7.89,1.008l49.009-13.75 c2.71-0.761,4.985-2.607,6.287-5.102C392.813,211.472,393.026,208.549,392.099,205.892z M303.876,212.393l-23.423-9.004 l20.435-45.319c2.355,1.689,4.87,3.166,7.522,4.4l6.048,25.922L303.876,212.393z M328.036,146.412 c-14.496,0-26.289-11.793-26.289-26.289c0-14.496,11.793-26.289,26.289-26.289s26.289,11.793,26.289,26.289 C354.325,134.618,342.532,146.412,328.036,146.412z M340.841,210.336l-10.486-43.584c8.762-0.43,16.896-3.287,23.744-7.912 l15.169,43.521L340.841,210.336z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M226.422,55.076h-88.733c-5.632,0-10.199,4.566-10.199,10.199s4.567,10.199,10.199,10.199h88.733 c5.632,0,10.199-4.566,10.199-10.199S232.054,55.076,226.422,55.076z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M226.422,103.012h-88.733c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h88.733 c5.632,0,10.199-4.566,10.199-10.199C236.622,107.578,232.054,103.012,226.422,103.012z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M226.422,150.948h-88.733c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h88.733 c5.632,0,10.199-4.566,10.199-10.199C236.622,155.514,232.054,150.948,226.422,150.948z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M375.841,290.677H136.159c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h239.681 c5.632,0,10.199-4.566,10.199-10.199C386.04,295.243,381.473,290.677,375.841,290.677z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M375.841,338.614H136.159c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h239.681 c5.632,0,10.199-4.566,10.199-10.199C386.04,343.18,381.473,338.614,375.841,338.614z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M375.841,386.55h-4.59c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h4.59 c5.632,0,10.199-4.566,10.199-10.199C386.04,391.116,381.473,386.55,375.841,386.55z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M334.534,386.55H136.159c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h198.375 c5.632,0,10.199-4.566,10.199-10.199C344.733,391.116,340.166,386.55,334.534,386.55z"></path>
                    </g>
                </g></g>
        </svg>
    </a>
);

export default IconCertificate;