import React from 'react';

const IconWebsite = ({ url, pdt, pdr }) => (
    <a href={url} target="_blank" rel="noreferrer" style={{ paddingTop: pdt, paddingRight: pdr }}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 491.52 491.52" style={{ fill: "#000000" }}>
            <g fill="#64ffda" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}>
                <g>
                    <g>
                        <polygon points="183.855,68.79 169.27,118.43 161.315,93.18 141.785,93.18 133.835,118.43 119.25,68.79 99.6,74.57 123.67,156.49 143.26,156.68 151.55,130.35 159.845,156.68 179.435,156.49 203.505,74.57 "></polygon>
                    </g>
                </g>
                <g>
                    <g>
                        <polygon points="298.545,68.79 283.955,118.43 276.005,93.18 256.475,93.18 248.525,118.43 233.935,68.79 214.285,74.57 238.36,156.49 257.95,156.68 266.24,130.35 274.53,156.68 294.12,156.49 318.195,74.57 "></polygon>
                    </g>
                </g>
                <g>
                    <g>
                        <polygon points="413.23,68.79 398.645,118.43 390.695,93.18 371.165,93.18 363.21,118.43 348.625,68.79 328.975,74.57 353.045,156.49 372.635,156.68 380.93,130.35 389.22,156.68 408.81,156.49 432.88,74.57 "></polygon>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M491.52,30.72H40.96v89.086C14.217,156.578,0,200.018,0,245.76c0,56.73,21.895,110.2,61.63,150.52 c40.7,41.61,95.185,64.52,153.41,64.52c58.225,0,112.71-22.91,153.385-64.49c39.76-40.35,61.655-93.82,61.655-150.55 c0-17.359-2.146-34.511-6.23-51.2h67.67V30.72z M40.96,159.031v35.529h58.021c-1.786,13.389-2.844,27.089-3.2,40.96H20.886 C22.271,208.614,29.064,182.749,40.96,159.031z M20.974,256h74.879c0.812,32.407,5.357,62.733,13.022,89.597 c-14.02,8.014-27.129,17.739-39.237,28.945C40.257,341.563,23.266,300.167,20.974,256z M84.004,389.117 c9.739-8.951,20.211-16.791,31.323-23.478c9.644,26.264,22.482,48.142,37.522,64.379 C127.497,421.474,104.162,407.642,84.004,389.117z M204.8,439.205c-28.946-6.164-54.591-38.093-70.783-83.479 c22.054-10.145,45.947-16.019,70.783-17.301V439.205z M204.8,317.944c-26.957,1.259-52.95,7.349-76.997,18.056 c-6.658-24.26-10.674-51.436-11.475-80H204.8V317.944z M204.8,235.52h-88.539c0.376-13.903,1.522-27.603,3.412-40.96H204.8V235.52 z M225.28,439.205V338.424c24.836,1.282,48.729,7.156,70.783,17.301C279.871,401.112,254.226,433.041,225.28,439.205z M313.752,256c-0.801,28.564-4.817,55.74-11.475,80c-24.047-10.706-50.039-16.797-76.997-18.056V256H313.752z M225.28,235.52 v-40.96h85.129c1.89,13.356,3.036,27.054,3.411,40.96H225.28z M277.229,430.019c15.041-16.237,27.88-38.116,37.524-64.381 c11.114,6.688,21.586,14.53,31.326,23.481C325.927,407.642,302.589,421.474,277.229,430.019z M360.443,374.543 c-12.109-11.207-25.217-20.931-39.238-28.946c7.665-26.864,12.21-57.191,13.022-89.597h74.879 C406.814,300.156,389.828,341.545,360.443,374.543z M334.299,235.52c-0.355-13.874-1.413-27.573-3.199-40.96h71.536 c3.641,13.349,5.854,27.053,6.578,40.96H334.299z M471.04,174.08H61.44V51.2h409.6V174.08z"></path>
                    </g>
                </g>
            </g>
        </svg>
    </a>
);

export default IconWebsite;