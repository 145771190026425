export const projects = [
  {
    title: "CyberBank",
    description:
      `Spearheaded the design and development of a Secure Banking System (SBS) web application, focusing on robust security, user-friendly interfaces, and efficient banking operations.`,
    techs: [
      "React", "Python", "Flask", "MUI", "JavaScript", "MySQL"
    ],
    github: "https://github.com/Shyam-Makwana/CyberBank",
    url: "https://cyberbank.netlify.app/",
    link: "https://cyberbank.netlify.app/",
  },
  {
    title: "BookEasy",
    description:
      `Led the development of a user-friendly Hotel Booking application that streamlined the booking process for travelers.
      Implemented a solution with MySQL as the database, resulting in a seamless experience for users and managers alike.
      Implemented a seamless user experience for finding and booking hotels, including search, selection, and payment
      processes, resulting in positive user feedback, elevating the sales, and increased bookings.`,
    techs: [
      "React", "Node.js", "AWS", "Azure", "MySQL"
    ],
    url: "https://bookeasy.netlify.app/",
    github: "https://github.com/Shyam-Makwana/BookEasy",
    link: "https://bookeasy.netlify.app/",
  },
  {
    title: "FoodSaver",
    description:
      `Responsive MERN Stack Web Application with Google Map API for accurate address and SendGrid API for sending Emails that passed Blackbox and Whitebox testing, developed with a goal to minimize food wastage. There are two users, one is a donor and the second one is the recipient, where the donor can donate the food and the recipient can receive that food.`,
    techs: [
      "React", "Node.js", "Rest API", "MongoDB", "Express JS", "Bootstrap",
    ],
    github: "https://github.com/Shyam-Makwana/FoodSaver",
    link: "https://thefoodsaver.netlify.app/",
    url: "https://thefoodsaver.netlify.app/",
  },
  {
    title: "GraphQL Playground",
    description:
      `Designed an interactive GraphQL playground to simplify learning through immediate query results, example queries, and a special dataset for hands-on exploration, enhancing user understanding and skills in GraphQL.`,
    techs: [
      "React", "Node.js", "GraphQL", "MUI", "Apollo GraphQL",
    ],
    github: "https://github.com/Shyam-Makwana/GraphQL",
    link: "https://graph--ql.vercel.app/",
    url: "https://graph--ql.vercel.app/",
  },
  {
    title: "Home Credit Default Risk",
    description:
      `Home Credit Default Risk is a machine learning project aimed at predicting the probability of loan default for Home Credit's customers.
    Utilized exploratory data analysis techniques, feature engineering, and machine learning algorithms to develop models for loan default prediction.
    Achieved high accuracy and performance metrics through data preprocessing, model selection, and hyperparameter tuning.`,
    techs: [
      "Python", "Scikit-learn", "PyTorch", "TensorFlow", "Pandas", "NumPy", "Matplotlib", "Seaborn"
    ],
    url: "https://github.com/Shyam-Makwana/Home-Credit-Default-Risk",
    github: "https://github.com/Shyam-Makwana/Home-Credit-Default-Risk",
  },
  {
    title: "All In One Compiler",
    description:
      `Web Application calculator that covers all the concepts of Compiler Design such as First & Follow, Left factoring, Left Recursion, Token Generation, and Self-Designed Compiler.`,
    techs: [
      "Flex", "Yacc", "C", "Node.js", "HTML", "JavaScript", "jQuery", "Ajax",
    ],
    github: "https://github.com/Shyam-Makwana/All-In-One-Compiler",
    link: "https://all-in-one-compiler.onrender.com",
    url: "https://all-in-one-compiler.onrender.com",
  },
  // {
  //   title: "Network Admission Control using Single Packet Authorization",
  //   description:
  //     `Developed an authentication protocol for ISRO that gives access to the user (client) to access any private services in the ISRO network. Understood the ISRO's current network architecture and modified it to Software Defined Perimeter model.`,
  //   techs: [
  //     "C++", "C#", ".Net", "Socket Programming",
  //   ],
  //   isro_report: true,
  // },
  {
    title: "Content-Based Book Recommendation System",
    description:
      `Devised a system to determine which books are close to each other and visualize which groups of books have similar topics based on how similar discussed topics are.
      Diagnosed text of all books in the dataset using NLTK and curated a distance matrix.
      Systemized all the information about book similarities using SciPy through a dendrogram.`,
    techs: [
      "Python", "Pandas", "NLTK", "Gensim", "MatPlotlib", "SciPy",
    ],
    github: "https://github.com/Shyam-Makwana/Content-Based-Book-Recommendation-System",
    url: "https://github.com/Shyam-Makwana/Content-Based-Book-Recommendation-System",
  },
  {
    title: "Customer Clustering for Marketing Purpose",
    description:
      `Pre-processed raw dataset and performed exploratory data analysis.
      Performed Principal Component Analysis on the dataset using sklearn to reduce the dimensionality of data set while retaining as much information as possible.
      Grouped given unlabelled customer dataset into 6 clusters using unsupervised K-means clustering algorithm.
      Visualized the results using Matplotlib.`,
    techs: [
      "Python", "MySQL", "Django", "HTML", "Css", "JavaScript"
    ],
    github: "https://github.com/Shyam-Makwana/Customer-Clustering-for-marketing-purpose",
    url: "https://github.com/Shyam-Makwana/Customer-Clustering-for-marketing-purpose",
  },
  {
    title: "Chatbot for searching restaurants and table booking",
    description:
      `Trained a chatbot that answers users’ queries about a restaurant in their city.
      Engineered the chatbot to assist the user for booking a table at their favorite restaurant and cancel a booking upon user’s request.
      Remodelled the chatbot to display a list of restaurants that fit according to user’s preferences.`,
    techs: ["Python", "RASA", "MySQL", "Django", "HTML", "Css", "JavaScript",],
    github: "https://github.com/Shyam-Makwana/Chatbot-for-Searching-Restaurants-and-Booking-Table",
    url: "https://github.com/Shyam-Makwana/Chatbot-for-Searching-Restaurants-and-Booking-Table",
  },
  {
    title: "Real-time Insights from Social Media Data",
    description:
      `Analyzing Twitter data and doing a deep dive into a hot trend tweets. Using pre-downloaded datasets to understand the nuts and 
      bolts of Twitter Data and thorough analysis of a hot-trend tweets of Twitter.`,
    techs: ["Python", "Json", "Pandas", "Matplotlib", "Twitter API",],
    github: "",
  },
  {
    title: "Grad School Admission Recommendation System",
    description:
      `An AI expert system, developed using Prolog that answers an array of user queries from the knowledge base and is specially designed for students aspiring to take admission in the US and Canada Graduate Schools. The system serves a list of universities that are in a certain region, provide scholarships, or are likely to accept user's admit based on his/her qualifications.`,
    techs: ["Python", "JavaScript", "CSS", "HTML"],
    github: "",
  },
  {
    title: "CoronaMap using Mapbox",
    description:
      "I created a Map using MapBox which shows total cases, deaths, sick, and Recovered people of Corona.",
    techs: ["MapBox", "JavaScript", "CSS", "HTML"],
    github: "https://github.com/Shyam-Makwana/CoronaMap",
    link: "https://shyammakwana.com/CoronaMap/coronamap.html",
    url: "https://shyammakwana.com/CoronaMap/coronamap.html",
  },
  {
    title: "Chrome Extension",
    description:
      "Chrome Extension for finding the meaning of any selected word on any web page.",
    techs: ["JavaScript", "CSS", "HTML"],
    github: "https://github.com/Shyam-Makwana/Chrome-Extension",
    url: "https://github.com/Shyam-Makwana/Chrome-Extension",
  },
  {
    title: "Text Utility",
    description:
      "A very handy text tool where you can change between lower-case and upper-case letters, where you can capitalize, uncapitalize, convert to mix case, and transform your text in different formats according to your needs.",
    techs: ["Django", "Python", "JavaScript", "CSS", "HTML"],
    github: "https://github.com/Shyam-Makwana/Text-Utility",
    url: "https://github.com/Shyam-Makwana/Text-Utility",
  },
  {
    title: "E-Commerce Database System",
    description:
      "Designed ER diagram, Schema diagram, created tables, views, procedure and wrote more than 25 queries for different purposes.",
    techs: ["MySQL", "PL/SQL"],
    github: "https://github.com/Shyam-Makwana/Database-Management-System",
    url: "https://github.com/Shyam-Makwana/Database-Management-System",
  },
];
